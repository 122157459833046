<template>
  <div class="mob-header">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/logo.png" alt="" />
      </router-link>
    </div>
    <div class="right">
      <div class="personal">
        <!-- 生成邀请卡按钮 -->
        <div v-if="watch && invitationSwitch" class="invite" @click="makeInvite()"></div>
        <button v-if="status === 'login'" @click="toLogin()">登录</button>
        <div v-else-if="status === 'info'" class="info">
          <span v-if="username">{{ userInfo.username }}</span>
          <router-link to="/personal">
            <div class="header-img">
              <img v-if="userInfo.wxheadimg" :src="userInfo.wxheadimg" alt="" />
              <img v-else src="../assets/default.png" alt="" />
            </div>
          </router-link>
        </div>
        <button v-else-if="status === 'logout'" @click="userLogout()">
          退出登录
        </button>
        <button v-else-if="status === 'collect' && !watch" @click="collectFn()">
          {{ collectStatus ? "已收藏" : "收藏" }}
        </button>
        <div
          v-else-if="status === 'collect' && watch"
          @click="collectFn()"
          :class="['icon-btn', collectStatus ? 'yessel' : 'nosel']"
        ></div>
      </div>
      <div v-if="interaction" class="interaction">
        <!-- <i @click="qrcodeShow = true">
          <img src="../assets/enquiry.png" alt="" />
        </i>
        <span></span> -->
        <i @click="toSearch()">
          <img src="../assets/search.png" alt="" />
        </i>
      </div>
    </div>
    <qrcode-popup :isShow="qrcodeShow" @close="closeQrcode"></qrcode-popup>
    <div v-if="inviteVisible" class="invite-box">
      <vue-canvas-poster
      v-if="!inviteImg"
        :widthPixels="1000"
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
      <div class="img-box">
        <img class="invite-img" :src="inviteImg" alt="">
        <div class="tips">
          <p>邀请卡已生成，赶快去分享</p>
          <div @click="closeInvite()">
            <img src="../assets/close3.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @property {String} status 'login'---显示登录按钮，'info'---显示姓名头像，'logout'---显示退出登录按钮，'collect'---显示收藏按钮
 * @property {Boolean} interaction 是否显示询价和搜索按钮
 * @property {Boolean} watch 是否在直播间页面
 * @property {Number} invitationSwitch 邀请卡开关
 */
import QrcodePopup from "../components/qrcode-popup.vue";
import { userInfo, logout, collect, unCollect, inviteInfo } from "../api/user";
import { getToken, removeToken } from "../utils/auth";
import { VueCanvasPoster } from "vue-canvas-poster";
export default {
  components: {
    QrcodePopup,
    VueCanvasPoster,
  },
  props: {
    // status: {
    //   type: String,
    //   default: "login",
    // },
    logout: {
      type: Boolean,
      default: false,
    },
    interaction: {
      type: Boolean,
      default: true,
    },
    username: {
      type: Boolean,
      default: false,
    },
    collectStatus: {
      type: Boolean,
      default: false,
    },
    collectData: Object,
    liveStatus: String,
    watch: {
      type: Boolean,
      default: false,
    },
    invitationSwitch: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      qrcodeShow: false,
      userInfo: {},
      status: "",
      inviteVisible: false,
      inviteImg: "",
      painting: {},
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    incomeDetail(date) {
      let y = date.substring(0, 4)
      let M = date.substring(5, 7)
      let d = date.substring(8, 10)
      let time = date.substring(11)
    let dateStr = y + "/" + M + "/" + d;
    return `${y}年${M}月${d}日 星期${"日一二三四五六".charAt(new Date(dateStr).getDay())} ${time}`
    },
    // 生成邀请卡
    makeInvite() {
        if (!this.inviteImg) {
          inviteInfo({
        id: this.$route.params.id
      }).then(res => {
        if (res.code == 0) {
          this.painting = {
            width: "1080px",
            height: "1916px",
            background: require("../assets/invite.jpg"),
            views: [
              {
                type: "image",
                url: res.data.wxHeadImg || require('../assets/defhead.png'),
                css: {
                  top: "450px",
                  left: "58px",
                  borderWidth: "5px",
                  borderRadius: "50%",
                  borderColor: "#ffffff",
                  width: "140px",
                  height: "140px",
                },
              },
              {
                type: 'text',
                text: res.data.wxNickName || res.data.userName,
                css: {
                  top: '470px',
                  left: '230px',
                  fontSize: '45px',
                  fontWeight: '600',
                  color: '#ffffff',
                  maxLines: 1,
                  width: '270px'
                }
              },
              {
                type: 'text',
                text: res.data.subject,
                css: {
                  top: '710px',
                  left: '170px',
                  fontSize: '56px',
                  lineHeight: '90px',
                  fontWeight: '600',
                  maxLines: 3,
                  width: '740px',
                  textAlign: 'center'
                }
              },
              {
                type: 'text',
                text: res.data.liveTime ? '直播时间' : '',
                css: {
                  top: '1055px',
                  left: '455px',
                  fontSize: '42px',
                  fontWeight: '600',
                }
              },
              {
                type: 'text',
                text: res.data.liveTime ? this.incomeDetail(res.data.liveTime) : '',
                css: {
                  top: '1130px',
                  left: '178px',
                  fontSize: '42px',
                  fontWeight: '600',
                  width: '730px',
                  textAlign: 'center'
                }
              },
              {
                type: 'qrcode',
                content: res.data.sourceUrl,
                css: {
                  top: '1330px',
                  left: '442px',
                  width: '190px',
                  height: '190px'
                }
              }
            ],
          }
          // if (res.data.wxHeadImg) {
          //   this.painting.views.push({
          //       type: "image",
          //       url: res.data.wxHeadImg,
          //       css: {
          //         top: "450px",
          //         left: "58px",
          //         borderWidth: "5px",
          //         borderRadius: "50%",
          //         borderColor: "#ffffff",
          //         width: "140px",
          //         height: "140px",
          //       },
          //     })
          // }
          // if (res.data.wxNickName) {
          //   this.painting.views.push(
          //     {
          //       type: 'text',
          //       text: res.data.wxNickName,
          //       css: {
          //         top: '470px',
          //         left: '230px',
          //         fontSize: '45px',
          //         fontWeight: '600',
          //         color: '#ffffff',
          //         maxLines: 1,
          //         width: '270px'
          //       }
          //     })
          // }
          this.inviteVisible = true;
          // console.log(this.incomeDetail(res.data.liveTime))
        }
      })
        } else {
          this.inviteVisible = true;
        }
      
      
    },
    closeInvite() {
      this.inviteVisible = false;
    },
    success(src) {
      // console.log(src);
      this.inviteImg = src
    },
    fail(err) {
      console.log("fail", err);
    },
    toLogin() {
      this.$router.push({ path: "/login" });
    },
    toSearch() {
      this.$router.push({ path: "/search" });
    },
    closeQrcode(val) {
      this.qrcodeShow = val;
    },
    // 退出登录
    userLogout() {
      logout({
        token: null,
      }).then((res) => {
        if (res.code == 0) {
          this.status = "login";
          // setToken(null);
          removeToken();
          this.$router.replace({ path: "/home" });
        }
      });
    },
    // 查询用户信息
    getUserInfo() {
      if (getToken()) {
        userInfo().then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data;

            if (this.$route.name == "Watch") {
              this.status = "collect";
            } else if (this.logout) {
              this.status = "logout";
            } else {
              this.status = "info";
            }
            // this.$store.commit("SET_USERID", res.data.id);
            // sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          } else {
            this.status = "login";
          }
        });
      } else {
        this.status = "login";
      }
      // this.$emit("userId", this.userInfo.id);
    },
    // 点击收藏按钮
    collectFn() {
      if (this.collectStatus) {
        // 已收藏
        unCollect({
          id: this.collectData.id,
          courseId: this.$route.params.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消收藏");
            this.$emit("refDataList");
            // this.collectStatus = null;
          }
        });
      } else {
        // 收藏
        collect({
          courseId: this.$route.params.id,
          // userId: this.userId,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("收藏成功");
            this.$emit("refDataList");
            // this.collectStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
.mob-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.1rem;
  width: 100%;
  height: 0.47rem;
  background-color: rgba($color: #000000, $alpha: 0);
  .logo {
    width: 1.25rem;
  }
  .right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    // width: 1.45rem;
    color: #fff;
    .interaction {
      display: flex;
      height: 0.2rem;
      i {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
      }
      span {
        border: 1px solid #fff;
        margin: 0 0.1rem;
      }
    }
    .personal {
      display: flex;
      .invite {
        width: 0.2rem;
        height: 0.2rem;
        background: url(../assets/flay.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .icon-btn {
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.2rem;
      }
      .nosel {
        background: url(../assets/sle.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .yessel {
        background: url(../assets/sle2.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
      button {
        margin-left: 0.1rem;
        height: 0.25rem;
        padding: 0 0.15rem;
        border: 1px solid #fff;
        background-color: rgba($color: #000000, $alpha: 0);
        color: #fff;
        border-radius: 0.125rem;
        font-size: 0.12rem;
      }
      .info {
        display: flex;
        span {
          line-height: 0.32rem;
          font-size: 0.12rem;
        }
        .header-img {
          position: relative;
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 0.18rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        // .header-img::after {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   content: "";
        //   display: block;
        //   top: 0;
        //   left: 0;
        //   background: url("../assets/default.png") 0 0 no-repeat;
        //   background-size: 100%;
        // }
      }
    }
  }
}
.invite-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9999;
  .img-box {
    width: 3.05rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // top: 0.3rem;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // margin: auto;
    .invite-img {
      -webkit-user-drag: auto;
        -webkit-user-select: auto;
        -moz-user-select: auto;
        user-select: auto;
        pointer-events: auto;
    }
    .tips {
      display: flex;
      justify-content: space-between;
      padding-top: 0.1rem;
      p {
        margin: 0;
        font-size: 0.12rem;
        color: #fff;
        height: 0.3rem;
        line-height: 0.3rem;
      }
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
}
</style>
